export default defineNuxtRouteMiddleware(async (to, from) => {
  const widget = useWidget()
  const widgetOptions = useWidgetOptions()
  const { $api } = useNuxtApp()
  const { isLoggedIn, clearToken, getData } = useSimAuthorization()

  const params = to.params || {}
  const simHashId = <string>params.simHashId

  if (!isLoggedIn) {
    return widget.redirectTo(`sim/${simHashId}/login`)
  } else {
    try {
      const data = getData() as { partnerId: number }
      const responseCheck = await $api.sim.checkSimLogin(simHashId)

      if (data.partnerId === widgetOptions.getPartnerId()) {
        if (responseCheck && responseCheck.check) return
      }

      clearToken()
      return widget.redirectTo(`sim/${simHashId}/login`)
    } catch (error) {
      return widget.redirectTo(`sim/${simHashId}/login`)
    }
  }
})
